<template lang="pug">
  v-container
    base-navigation-tabs(
      :items="links"
    )
    router-view
</template>
<script>

export default {
  name: 'Vouchers',
  data () {
    return {
      links: [
        { label: 'Vouchers', path: '/vouchers' },
        { label: 'Pt Cash Vouchers', path: '/vouchers/petty-cash' },
        { label: 'Account Code', path: '/vouchers/account-codes' },
        { label: 'Cost Center', path: '/vouchers/cost-centers' },
        { label: 'Revenue Source', path: '/vouchers/revenue-sources' },
      ],
    }
  },
}
</script>